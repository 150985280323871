import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

export const query = graphql`
  query($pathSlug: String!) {
    mdx(slug: { eq: $pathSlug }) {
      slug
      frontmatter {
        title
        createDate(formatString: "DD MMMM YYYY")
        updateDate(formatString: "DD MMMM YYYY")
        path
      }
      body
      tableOfContents
    }
  }
`;

const Template = ({ data: { mdx } }) => {
  // console.log('DEFAULT TEMPLATE mdx------', mdx);
  // const main = mdx.filter((element) => element.slug.)
  const { slug, body } = mdx;

  console.log('slug', slug);

  return (
    <div className="container mx-auto px-4 mt-5">
      <br />
      <MDXRenderer>{body}</MDXRenderer>
    </div>
  );
};

export default Template;
